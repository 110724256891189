import { LOCALES } from "../locales";

export default {
  [LOCALES.RUSSIAN]: {
    // Navbar
    mobileCloseBtn: "Открыть/закрыть меню",
    howItWorksBtn: "Как работает",
    instructionBtn: "Инструкция",
    recommendationsBtn: "Как принимать",
    faqBtn: "Вопросы-ответы",
    pharmacyBtn: "Поиск в аптеках",

    // Promo
    promo__title: "Дыши, не кашляй!",
    promo__text: "Для устранения симптомов простуды и кашля",

    // How It Works
    howItWorks__title: "Как работает?",
    howItWorks__paragraph:
      "Способствует разжижению{break} и выведению мокроты, {break}и уменьшению кашля.",

    // How To / Instructions
    howTo__title: "Инструкция",
    howTo__item_p1: "20 таблеток в упаковке",
    howTo__item_p2: "Содержит 30 мг амброксола",
    howTo__item_p3: "Производитель: ПРО.МЕД.ЦС Прага а. о., Чехия",

    //Recommendations
    recommendations__title: "Как{space}принимать?",
    recommendations__list_p1:
      "Взрослые и дети старше 12 лет:{break} 1 таблетка 3 раза в сутки. Лечебное воздействие может быть усилено приемом 2 таблеток 2 раза в сутки.",
    recommendations__list_p2:
      "Дети 6-12 лет:{break} ½ таблетки 2-3 раза в сутки. Лечебный эффект может быть усилен приёмом ½ таблетки 4-6 раз в сутки",
    recommendations__list_p3:
      "Таблетки следует принимать после еды, запивая достаточным количеством жидкости",

    // Pharmacy
    pharmacy__title: "Поиск в аптеках",
    pharmacy__mainText: "Спрашивайте в аптеках страны",

    // FAQ
    faq__title: "Вопросы и ответы",
    faq__list_q1: "Амбросан - это лекарство или БАД?",
    faq__list_q1_an: "Это лекарственный препарат, отпускается без рецепта",
    faq__list_q2: "Когда следует принимать Амбросан?",
    faq__list_q2_an:
      "Амбросан относится к группе препаратов для устранения симптомов простуды и кашля.",
    faq__list_q3:
      "Можно ли принимать Амбросан с другими лекарственными средствами? ",
    faq__list_q3_an:
      "{bullet}Применение препарата Амбросан одновременно с антибиотиками приводит к повышению концентрации антибиотиков в тканях лёгких; {break}{bullet}Не рекомендована комбинация со средствами против кашля.",
    faq__list_q4: "Когда  наступает эффект после приёма Амбросана?",
    faq__list_q4_an: "Через 1-2,5 часов",
    faq__list_q5: "Где производится Амбросан?",
    faq__list_q5_an: "ПРО.МЕД.ЦС Прага а. о., Чехия",

    // Contraindications
    contraindications__title: "Противопоказания",
    contraindications__list_p1:
      "{space}{space}{space}<code>Противопоказания:</code> повышенная чувствительность к активному веществу или любому вспомогательному веществу.  Детский возраст до 6 лет.",
    contraindications__list_p2:
      "{space}{space}{space}<code>Во время беременности или лактации.</code> Амброксола гидрохлорид проникает через плацентарный барьер. Исследования на животных не свидетельствуют о прямых или косвенных вредных воздействиях, это касается беременности, эмбрионального/фетального развития, родов или постнатального развития. Обширный клинический опыт свидетельствуют о том, что применение данного препарата после 28 недель беременности не выявил вредных воздействий на плод. Несмотря на это, необходимо соблюдать основные правила приема лекарств во время беременности. Особенно в I триместре не рекомендуется применение препаратов, содержащих амброксола гидрохлорид. Амброксола гидрохлорид поступает в материнское молоко. Хотя у кормленных грудью детей не ожидается побочных действий, применение препарата Амбросан 30 мг не рекомендуется кормящим матерям.",

    // Footer
    footer_p1:
      "Держатель регистрационного удостоверения {break} ПРО.МЕД.ЦС Прага а. о., {break} Телчска 377/1, Михле, 140 00 Прага 4, Чешская Республика, {break} Телефон/ факс: 00420 241013369; {break} e-mail: promed@promedcs.com {break} НОМЕР РЕГИСТРАЦИОННОГО УДОСТОВЕРЕНИЯ {break} KG.3.3.6.09859-2023 от 25.01.2023г.",
    footer_p2:
      "Контактная информация: {break} г. Бишкек, ул. Игембердиева, 1А, 408 , {break} Телефон: +996312547094",
    footer_p3: "",

    // Warning
    warning:
      "ИМЕЮТСЯ ПРОТИВОПОКАЗАНИЯ, НЕОБХОДИМО ПРОКОНСУЛЬТИРОВАТЬСЯ СО СПЕЦИАЛИСТОМ. САМОЛЕЧЕНИЕ МОЖЕТ БЫТЬ ВРЕДНЫМ ДЛЯ ВАШЕГО ЗДОРОВЬЯ",
  },
};
