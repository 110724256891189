// import "./App.css";

import Homerus from "./components/HomeRus/Homerus";
import { BrowserRouter, Route, Routes, Redirect } from "react-router-dom";
import Homekg from "./components/Homekg/Homekg";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/kg" element={<Homekg />} />
        <Route path="/" element={<Homerus />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
