import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Warning from "../Warning/Warning";
import { I18Provider, LOCALES } from "../../i18l";

export default function Homerus() {
  return (
    <I18Provider locale={LOCALES.RUSSIAN}>
      <>
        <Header />
        <Main videoLang="RU" />
        <Footer />
        <Warning />
      </>
    </I18Provider>
  );
}
