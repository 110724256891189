import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

export default function Warning() {
  const [closeWarning, setCloseWarning] = useState({});
  const handleClose = (e) => {
    setCloseWarning({ display: "none" });
  };

  return (
    <div className="warning" style={closeWarning}>
      <div className="container">
        <div className="warning__container">
          <button
            className="warning-button warning__button"
            onClick={handleClose}
          >
            <span className="visually-hidden">Закрыть</span>
            <span className="warning-button__icon"></span>
          </button>
          <div className="warning__content">
            <p className="warning__text">
              <FormattedMessage id="warning" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
