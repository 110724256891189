import { LOCALES } from "../locales";

export default {
  [LOCALES.KYRGYZ]: {
    // Navbar
    mobileCloseBtn: "Открыть/закрыть меню",
    howItWorksBtn: "Кантип иштейт",
    instructionBtn: "Нускама",
    recommendationsBtn: "Кантип кабыл алуу керек",
    faqBtn: "Суроолор - жооптор",
    pharmacyBtn: "Дарыканалардан издөө",

    // Promo
    promo__title: "Дем ал, жөтөлбө!",
    promo__text: "Суук тийүүнүн белгилерин жана жөтөлдү жок кылуу үчүн",

    // How It Works
    howItWorks__title: "Кандай иштейт?",
    howItWorks__paragraph:
      "Какырыкты суюлтуп чыгарат,{break} жана жөтөлдү азайтат.",

    // How To / Instructions
    howTo__title: "Нускама",
    howTo__item_p1: "20 таблетка бир упаковкада",
    howTo__item_p2: "Төмөнкүлөрдү камтыйт 30 мг амброксол",
    howTo__item_p3: "Өндүрүүчү: ПРО.МЕД.ЦС Прага а. к., Чехия",

    //Recommendations
    recommendations__title: "Кандай{space}ичүү керек?",
    recommendations__list_p1:
      "Чоң адамдар жана 12 жаштан жогору балдар: 1 таблеткадан суткасына  3 жолу. 2 таблеткадан суткасына 2 жолудан ичип, дарылык таасирин күчөтүүгө болот.",
    recommendations__list_p2:
      "6-12 жаштагы балдар: ½ таблеткадан суткасына  2-3 жолу. ½ таблеткадан суткасына 4-6 жолу ичип, дарылык таасирин күчөтүүгө болот.",
    recommendations__list_p3:
      "Таблеткаларды тамактан кийин жетиштүү өлчөмдөгү суюктук менен жутуп ичүү керек.",

    // Pharmacy
    pharmacy__title: "Дарыканалардан издөө",
    pharmacy__mainText: "Өлкөнүн дарыканаларынан сураңыз",

    // FAQ
    faq__title: "Суроолор- жооптор",
    faq__list_q1:
      "Амбросан – бул дары каражатыбы же биологиялык активдүү кошулмабы?",
    faq__list_q1_an: "Бул дары препараты, рецепти жок берилет.",
    faq__list_q2: "Амбросанды качан ичүүгө болот?",
    faq__list_q2_an:
      "Амбросан суук тийүү симптомдорун жана жөтөлдү жок кылуучу препараттардын тобуна кирет.",
    faq__list_q3: "Амбросанды башка дары каражаттары менен колдонууга болобу?",
    faq__list_q3_an:
      "Амбросан препаратын антибиотиктер менен бир убакта колдонуу өпкө ткандарындагы антибиотиктердин концентрациясынын жогорулашына алып келет. {break} Жөтөлгө каршы каражаттар менен айкалыштыруу сунушталбайт.",
    faq__list_q4: "Амбросанды ичкенден кийин таасири качан башталат?",
    faq__list_q4_an: "1-2,5 сааттан кийин",
    faq__list_q5: "Амбросан кайда өндүрүлөт?",
    faq__list_q5_an: "ПРО.МЕД.ЦС Прага а. к., Чехия",

    // Contraindications
    contraindications__title: "Каршы көрсөтмө",
    contraindications__list_p1:
      "{space}{space}{space}<code>Каршы көрсөтмө:</code> активдүү затка же көмөкчү заттардын кайсы бирине жогору сезгичтик.  6 жашка чейинки балдар курагы.",
    contraindications__list_p2:
      "{space}{space}{space}<code>Кош бойлуулукта жана бала эмизүү мезгили.</code> Амброксол гидрохлориди бала тону тоскоолдугу аркылуу өтөт. Жаныбарларга изилдөөлөр кош бойлуулукка, түйүлдүккө/феталдык өрчүүгө, төрөткө же төрөттөн кийинки өрчүүсүнө түз же кыйыр таасирлери тууралуу күбөлөндүрбөйт. Кеңири клиникалык тажрыйба кош бойлуулуктун 28 жумасынан кийин бул препаратты колдонууда түйүлдүккө зыян таасири аныкталбагандыгын күбөлөндүрөт. Ага карабастан, кош бойлуу мезгилде дары ичүүнүн негизги эрежелерин сактоо зарыл. Өзгөчө I үч айлыкта амброксол гидрохлоридин камтыган препараттарды колдонуу сунушталбайт. Амброксол гидрохлориди эне сүтүнө кирет. Эне сүтүн эмген балдарда кыйыр таасирлери күтүлбөсө да, Амбросан 30 мг препаратын бала эмизген энелерге колдонуу сунушталбайт.",

    // Footer
    footer_p1:
      "Каттоо күбөлүгүнүн ээси {break} ПРО.МЕД.ЦС Прага а. к., {break} Телчска 377/1, Михле, 140 00 Прага 4, Чехия Республикасы, {break} Телефон/ факс: 00420 241013369; {break} e-mail: promed@promedcs.com {break} КАТТОО КҮБӨЛҮГҮНҮН НОМЕРИ {break} KG.3.3.6.09859-2023 от 25.01.2023г.",
    footer_p2:
      "Байланышуу үчүн маалымат: Бишкек ш, Игембердиев көч, 1А, 408, {break} Телефон: +996312547094",
    footer_p3: "",

    // Warning
    warning:
      "КАРШЫ КӨРСӨТМӨЛӨРҮ БАР, КОЛДОНУУДАН МУРУН АДИС МЕНЕН КЕҢЕШИҢИЗ. ӨЗҮҢДҮ ДАРЫЛОО ДЕН СООЛУГУНУЗГА ЗЫЯН АЛЫШЫ МҮМКҮН",
  },
};
