import React from "react";
import mainVideoRus from "../../assets/videos/main-video_ru.mp4";
import mainVideoKg from "../../assets/videos/main-video_kg.mp4";

const mainVideo = {
  RU: mainVideoRus,
  KG: mainVideoKg,
};

export default function Video({ lang }) {
  return (
    <section class="video">
      <div class="container">
        <video
          class="video__video"
          src={mainVideo[lang]}
          autoplay="autoplay"
          loop=""
          playsinline=""
          muted="muted"
        ></video>
      </div>
    </section>
  );
}
