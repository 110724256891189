import React from "react";
import { FormattedMessage } from "react-intl";
import ph1 from "../../assets/images/1.png";
import ph2 from "../../assets/images/2.png";
import ph3 from "../../assets/images/3.png";

export default function Pharmacy() {
  return (
    <section class="pharmacy" id="pharmacy">
      <div class="container">
        <div class="pharmacy__container">
          <h2 class="pharmacy__title title">
            <FormattedMessage id="pharmacy__title" />
          </h2>
          <h2 class="pharmacy__main-text">
            <FormattedMessage id="pharmacy__mainText" />
          </h2>
          {/* <ul class="pharmacy__list">
            <li class="pharmacy__item">
              <a
                class="pharmacy__link"
                // href="https://biosfera.kz/product/search?search=%D0%B0%D0%BC%D0%B1%D1%80%D0%BE%D1%81%D0%B0%D0%BD"
                target="_blank"
              >
                <img class="pharmacy__logo" src={ph1} alt="Biosphere" />
              </a>
            </li>
            <li class="pharmacy__item">
              <a
                class="pharmacy__link"
                // href="https://europharma.kz/ambrosan-30-mg-no-20-tabl"
                target="_blank"
              >
                <img class="pharmacy__logo" src={ph2} alt="Europharma" />
              </a>
            </li>
            <li class="pharmacy__item">
              <a
                class="pharmacy__link"
                // href="https://gippokrat.kz/product/1696-ambrosan-30mg-tb-20/"
                target="_blank"
              >
                <img class="pharmacy__logo" src={ph3} alt="Hippocratic" />
              </a>
            </li>
          </ul> */}
        </div>
      </div>
    </section>
  );
}
