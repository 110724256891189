import React from "react";
import { FormattedMessage } from "react-intl";

export default function FAQ() {
  return (
    <section class="qna" id="faq">
      <div class="container">
        <div class="qna__container">
          <h2 class="qna__title title">
            <FormattedMessage id="faq__title" />
          </h2>

          <div class="qna__content">
            <ul class="qna__list">
              <li class="qna__item item item__question">
                <p class="item__text">
                  <FormattedMessage id="faq__list_q1" />
                </p>
              </li>
              <li class="qna__item item item__answer">
                <p class="item__text">
                  <FormattedMessage id="faq__list_q1_an" />
                </p>
              </li>
              <li class="qna__item item item__question">
                <p class="item__text">
                  <FormattedMessage id="faq__list_q2" />
                </p>
              </li>
              <li class="qna__item item item__answer">
                <p class="item__text">
                  <FormattedMessage id="faq__list_q2_an" />
                </p>
              </li>
              <li class="qna__item item item__question">
                <p class="item__text">
                  <FormattedMessage id="faq__list_q3" />
                </p>
              </li>
              <li class="qna__item item item__answer">
                <p class="item__text">
                  <FormattedMessage
                    id="faq__list_q3_an"
                    values={{ break: <br />, bullet: "\u2219" }}
                  />
                </p>
              </li>
              <li class="qna__item item item__question">
                <p class="item__text">
                  <FormattedMessage id="faq__list_q4" />
                </p>
              </li>
              <li class="qna__item item item__answer">
                <p class="item__text">
                  <FormattedMessage id="faq__list_q4_an" />
                </p>
              </li>
              <li class="qna__item item item__question">
                <p class="item__text">
                  <FormattedMessage id="faq__list_q5" />
                </p>
              </li>
              <li class="qna__item item item__answer">
                <p class="item__text">
                  <FormattedMessage id="faq__list_q5_an" />
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
