import React from "react";
import lungs375 from "../../assets/videos/lungs_375.mp4";
import lungs_1366 from "../../assets/videos/lungs_1366.mp4";
import lungs1920 from "../../assets/videos/lungs_1920.mp4";
import logo from "../../assets/images/logo.svg";
import { FormattedMessage } from "react-intl";

export default function HowItWorks() {
  return (
    <section className="how-it-works" id="how-it-works">
      <div className="how-it-works__container">
        <h2 className="how-it-works__title title">
          <FormattedMessage id="howItWorks__title" />
        </h2>

        <div className="how-it-works__wrapper">
          <div className="how-it-works__text">
            <img className="how-it-works__logo" src={logo} alt="Амбросан" />
            <p className="how-it-works__paragraph">
              <FormattedMessage
                id="howItWorks__paragraph"
                values={{
                  break: <br />,
                }}
              />
            </p>
          </div>
          <video
            className="how-it-works__video how-it-works__video_mobile"
            src={lungs375}
            autoplay="autoplay"
            loop
            playsinline=""
            muted="muted"
          ></video>

          <video
            className="how-it-works__video how-it-works__video_desktop"
            src={lungs_1366}
            autoplay="autoplay"
            loop
            playsinline=""
            muted="muted"
          ></video>
          {/* <div className="video-desktop-xl-wrapper"> */}
          <video
            className="how-it-works__video how-it-works__video_desktop-xl"
            src={lungs1920}
            autoplay="autoplay"
            loop
            playsinline=""
            muted="muted"
          ></video>
          {/* </div> */}
          <p className="how-it-works__paragraph__mobile">
            <FormattedMessage
              id="howItWorks__paragraph"
              values={{
                break: <br />,
              }}
            />
          </p>
        </div>
      </div>
    </section>
  );
}
