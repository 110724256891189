import React from "react";
import { FormattedMessage } from "react-intl";

export default function Footer() {
  return (
    <footer className="footer" id="footer">
      <div className="container">
        <div className="footer__container">
          <div className="footer__content">
            <div className="footer__col">
              <p className="footer__text">
                <FormattedMessage id="footer_p1" values={{ break: <br /> }} />
              </p>
              <p className="footer__text">
                <FormattedMessage id="footer_p2" values={{ break: <br /> }} />
              </p>
            </div>
          </div>
          {/* 
          <p>
            <FormattedMessage id="footer_p3" />
          </p> */}
        </div>
      </div>
    </footer>
  );
}
