import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import pills from "../../../assets/images/pills.png";

export default function Menu() {
  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };
  return (
    <div className={`menu header__menu ${isActive ? "menu_opened" : ""}`}>
      <button className="menu-button menu__button" onClick={handleToggle}>
        <span className="menu-button__dash"></span>
        <span className="menu-button__dash"></span>
        <span className="menu-button__dash"></span>
        <span className="visually-hidden">
          <FormattedMessage id="mobileCloseBtn" />
        </span>
      </button>

      <div className="menu__content">
        <ul className="menu__list">
          <li className="menu__item">
            <a className="menu__link" href="#how-it-works">
              <FormattedMessage id="howItWorksBtn" />
            </a>
          </li>

          <li className="menu__item">
            <a className="menu__link" href="#how-to">
              <FormattedMessage id="instructionBtn" />
            </a>
          </li>

          <li className="menu__item">
            <a className="menu__link" href="#recommendations">
              <FormattedMessage id="recommendationsBtn" />
            </a>
          </li>

          <li className="menu__item">
            <a className="menu__link" href="#faq">
              <FormattedMessage id="faqBtn" />
            </a>
          </li>
        </ul>

        <a className="menu__search green-link" href="#pharmacy">
          <FormattedMessage id="pharmacyBtn" />
        </a>

        <img className="menu__image" src={pills} alt="Упаковка Амбросан" />
      </div>
    </div>
  );
}
