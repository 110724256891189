import React from "react";
import Contraindications from "../Contraindications/Contraindications";
import FAQ from "../FAQ/FAQ";
import HowItWorks from "../HowItWorks/HowItWorks";
import HowTo from "../HowTo/HowTo";
import Pharmacy from "../Pharmacy/Pharmacy";
import Promo from "../Promo/Promo";
import Recommendations from "../Recommendations/Recommendations";
import Video from "../Video/Video";

export default function Main({ videoLang }) {
  return (
    <main className="main">
      <h1 className="visually-hidden">Амбросан</h1>
      <Promo />
      <HowItWorks />
      <HowTo lang={videoLang} />
      <Recommendations />
      <Pharmacy />
      <Video lang={videoLang} />
      <FAQ />
      <Contraindications />
    </main>
  );
}
