import React from "react";
import logo from "../../assets/images/logo.svg";
import howToImg from "../../assets/images/how-to-package.png";
import { FormattedMessage } from "react-intl";
import instRus from "../../assets/instructions/instruction_ru.pdf";
import instKg from "../../assets/instructions/instruction_kg.pdf";

export default function HowTo({ lang }) {
  const instLang = {
    RU: instRus,
    KG: instKg,
  };
  return (
    <section className="how-to" id="how-to">
      <div className="container">
        <div className="how-to__container">
          <h2 className="how-to__title title">
            <FormattedMessage id="howTo__title" />
          </h2>
          <img className="how-to__logo" src={logo} alt="Амбросан" />

          <div className="how-to__content">
            <ul className="how-to__list">
              <li className="how-to__item item">
                <p className="item__text">
                  <FormattedMessage id="howTo__item_p1" />
                </p>
              </li>
              <li className="how-to__item">
                <p>
                  <FormattedMessage id="howTo__item_p2" />
                </p>
              </li>
              <li className="how-to__item">
                <p>
                  <FormattedMessage id="howTo__item_p3" />
                </p>
              </li>
            </ul>
            <img className="how-to__image" src={howToImg} alt="Амбросан" />
          </div>

          <a
            className="how-to__link green-link"
            href={instLang[lang]}
            target="_blank"
          >
            <FormattedMessage id="instructionBtn" />
          </a>
        </div>
      </div>
    </section>
  );
}
