import React from "react";
import logo from "../../assets/images/logo.svg";
import Menu from "./components/Menu";

export default function Header({ activeLang }) {
  return (
    <header className="header">
      <div className="container">
        <div className="header__container">
          <Menu />

          <a className="logo header__logo" href="/">
            <img className="logo__image" src={logo} alt="Амбросан" />
          </a>

          <ul className="header__lang lang">
            <li className="lang__item">
              <a
                className={
                  "lang__link" + `${!activeLang ? " lang__link_active" : ""}`
                }
                href="/"
              >
                ru
              </a>
            </li>

            <li className="lang__item">
              <a
                className={
                  "lang__link" + `${activeLang ? " lang__link_active" : ""}`
                }
                href="/kg"
              >
                kg
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
