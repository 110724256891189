import React from "react";
import { FormattedMessage } from "react-intl";

export default function Contraindications() {
  return (
    <section class="contraindications">
      <div class="container">
        <div class="contraindications__container">
          <h2 class="visually-hidden">
            <FormattedMessage id="contraindications__title" />
          </h2>
          <div class="contraindications__content">
            <p class="contraindications__text">
              <FormattedMessage
                id="contraindications__list_p1"
                values={{ space: "\u00A0", code: (word) => <b>{word}</b> }}
              />
            </p>
            <p class="contraindications__text">
              <FormattedMessage
                id="contraindications__list_p2"
                values={{ space: "\u00A0", code: (word) => <b>{word}</b> }}
              />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
