import React from "react";
import { FormattedMessage } from "react-intl";
import logo from "../../assets/images/logo.svg";

export default function Promo() {
  return (
    <section className="promo">
      <div className="promo__container">
        <div className="promo__content">
          <img className="promo__logo" src={logo} alt="Амбросан" />

          <h2 className="promo__title">
            <FormattedMessage id="promo__title" />
          </h2>

          <p className="promo__text">
            <FormattedMessage id="promo__text" />
          </p>

          <a href="#pharmacy" className="promo__link green-link">
            <FormattedMessage id="pharmacyBtn" />
          </a>
        </div>
      </div>
    </section>
  );
}
